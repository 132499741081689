import axios from 'axios'
import Utils from '@/services/Utils'
import moment from 'moment'

axios.defaults.timeout = 900000
axios.defaults.dev = true

const urls = Utils.getUrls()
axios.defaults.baseUrl = urls.base
axios.defaults.baseUrlV2 = urls.baseV2
axios.defaults.chatApiUrl = urls.chatApi
axios.defaults.chat2ApiUrl = urls.chat2Api
axios.defaults.chatWebviewUrl = urls.chatWebview
axios.defaults.storageUrl = urls.storage

const user = Utils.getUser()
if (user) {
    Utils.updateAuthorization(user)
    Utils.refreshUserFields(user)
}

export default {
    hasInternet(callback) {
        if (navigator && !navigator.onLine) {
            callback({
                success: false,
                message: window.strings && window.strings['no_internet'] ? window.strings['no_internet'] : 'Por favor verifica a tua conexão à internet',
            })
            return false
        } else {
            return true
        }
    },
    login(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        Utils.updateAuthorization(data)
        data.datetime = moment().format('YYYY-MM-DD HH:mm')

        axios.post(axios.defaults.baseUrl + 'login?website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getStrings(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'translations?lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getRegisterConfig(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'register_config?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getRegisterQuestions(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'register_questions?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getFeedbackConfig(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const cache = Utils.getRequestCache('feedback_config', data)
        if (cache) {
            return callback(cache)
        }

        axios.get(axios.defaults.baseUrl + 'feedback_config?db_id=' + axios.defaults.ptId + (data && data.type ? ('&type=' + data.type) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback, data.cacheKey)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientsRegisters(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'register?filter={"pt_id":' + axios.defaults.ptId + ',"type":1,"status":0}&order={"id":0}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientRegister(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'register?filter={"pt_id":' + axios.defaults.ptId + ', "id":' + data + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientRegisterWithChatId(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const toValidate = 0
        const validated = 1
        const status = [toValidate, validated]

        axios.get(axios.defaults.baseUrl + 'register?search={"pt_id":[' + axios.defaults.ptId + '],"chat_id":[' + data.chat_id + '],"status":' + JSON.stringify(status) + '}' + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getExportingRegistersData(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'register?query=[{"f":"pt_id","q":0,"v":' + axios.defaults.ptId + ',"n":1},{"f":"status","q":2,"v":2,"n":3},{"f":"paym_sub_status","q":0,"v":4,"n":2},{"f":"paym_sub_status","q":0,"v":99,"n":6},{"f":"date","d":1,"q":5,"v":"' + data.start_date + '","n":1},{"f":"date","d":1,"q":4,"v":"' + data.end_date + '","n":0}]&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientsNewSales(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'register?query=[{"f":"pt_id","q":0,"v":' + axios.defaults.ptId + ',"n":10},{"f":"status","q":0,"v":0,"n":1},{"f":"paym_sub_status","q":6,"v":[4,99],"n":7},{"f":"status","q":0,"v":1,"n":6},{"f":"date","tz":' + window.diffUtcHours + ',"d":1,"q":5,"v":"' + data.start_date + '","n":1},{"f":"date","tz":' + window.diffUtcHours + ',"d":1,"q":4,"v":"' + data.end_date + '","n":1},{"f":"promo_code","q":9,"v":"RM-","n":1},{"f":"promo_code","q":9,"v":"RS-","n":0}]&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientsReturnSales(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'register?query=[{"f":"pt_id","q":0,"v":' + axios.defaults.ptId + ',"n":1},{"f":"status","q":2,"v":2,"n":3},{"f":"paym_sub_status","q":0,"v":4,"n":2},{"f":"paym_sub_status","q":0,"v":99,"n":6},{"f":"date","tz":' + window.diffUtcHours + ',"d":1,"q":5,"v":"' + data.start_date + '","n":1},{"f":"date","tz":' + window.diffUtcHours + ',"d":1,"q":4,"v":"' + data.end_date + '","n":3},{"f":"promo_code","q":7,"v":"RM-","n":2},{"f":"promo_code","q":7,"v":"RS-","n":5}]&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientsSalesPayments(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'register_payment?query=[{"f":"pt_id","q":0,"v":' + axios.defaults.ptId + ',"n":1},{"f":"id","q":5,"v":' + data.ids[0] + ',"n":1},{"f":"id","q":4,"v":' + data.ids[1] + ',"n":1},{"f":"type","q":3,"v":0,"n":0}]&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateClientRegister(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = data.id
        delete data.id

        axios.patch(axios.defaults.baseUrl + 'register?id=' + id + '&pt_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientRegisterAssets(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'register_photo?filter={"register_id":' + data + ',"pt_id":' + axios.defaults.ptId + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateClientRegisterAssets(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'register_photo?register_id=' + data.id + '&website=1&lng=' + window.language,
            data.assets, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getAlerts(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const ptId = axios.defaults.ptId
        let userId = axios.defaults.userId
        if (data.id) {
            userId = data.id
        }
        let filter = ''
        if (data.totals) {
            filter += '&totals=1'
        }
        if (data.pending_payments) {
            filter += '&pending_payments=1'
        }
        if (data.received_payments) {
            filter += '&received_payments=1'
        }
        if (data.registers) {
            filter += '&registers=1'
        }
        if (data.initial_quizs) {
            filter += '&initial_quizs=1'
        }
        if (data.feedbacks) {
            filter += '&feedbacks=1'
        }
        if (data.payment_proofs) {
            filter += '&payment_proofs=1'
        }
        if (data.trng_plans) {
            filter += '&trng_plans=1'
        }
        if (data.phy_evals) {
            filter += '&phy_evals=1'
        }
        if (data.nutri_plans) {
            filter += '&nutri_plans=1'
        }
        if (data.birthdays) {
            filter += '&birthdays=1'
        }
        if (data.next_feedbacks) {
            filter += '&next_feedbacks=1'
        }
        if (data.pending_feedbacks) {
            filter += '&pending_feedbacks=1'
        }
        if (window.employeeIdLogged) {
            filter += '&employee=' + window.employeeIdLogged
        }

        axios.get(axios.defaults.baseUrl + 'alerts?id=' + userId + '&pt_id=' + ptId + filter + (data.next_feedbacks_date ? ('&next_feedbacks_date=' + data.next_feedbacks_date) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    validateClient(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        let filter = 'id=' + data.userId + '&pt_id=' + axios.defaults.ptId

        if (data.payday) {
            filter += '&payday=' + data.payday
        }

        axios.post(axios.defaults.baseUrl + 'register_validate?' + filter + '&website=1&lng=' + window.language,
            {}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    refuseClient(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const filter = 'id=' + data + '&pt_id=' + axios.defaults.ptId

        axios.post(axios.defaults.baseUrl + 'register_refuse?' + filter + '&website=1&lng=' + window.language,
            {}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newClient(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        data.pt_id = parseFloat(axios.defaults.ptId)

        axios.post(axios.defaults.baseUrl + 'user?website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateUser(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data.id ? data.id : axios.defaults.userId
        const log = data.log_source && window.employeeIdLogged ? ('&source=' + data.log_source + '&emp=' + window.employeeIdLogged) : null
        delete data.log_source

        axios.patch(axios.defaults.baseUrl + 'user?id=' + userId + '&pt_id=' + axios.defaults.ptId + (log ? log : '') + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateUsers(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const log = data.log_source && window.employeeIdLogged ? ('&source=' + data.log_source + '&emp=' + window.employeeIdLogged) : null
        delete data.log_source

        axios.patch(axios.defaults.baseUrl + 'user/bulk?ids=' + (JSON.stringify(data.ids)) + '&pt_id=' + axios.defaults.ptId + (log ? log : '') + '&website=1&lng=' + window.language,
            data.data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    addUserPhoto(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'user_photo?website=1&lng=' + window.language,
            {
                id: axios.defaults.userId,
                pt_id: axios.defaults.ptId,
                photo: data,
            }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateUserPhoto(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'user_photo?id=' + axios.defaults.userId + '&pt_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            {
                photo: data,
            }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getUser(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user?search={"email": ["' + data.email + '"],"type":[0,2]}&photo=1&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processGetUser(response, callback)
        }).catch((response) => {
            Utils.processGetUser(response, callback)
        })
    },
    getUserWithId(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user?filter={"id":' + data.id + ',"pt_id":' + axios.defaults.ptId + '}' + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&photo=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getUserWithEmail(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const activeStatus = 0
        const suspendedStatus = 1
        const pendingStatus = 3
        const pendingLoginStatus = 4
        const suspendedPaymentPending = 5
        const pendingReturnStatus = 6
        const pendingInitialQuiz = 99
        const status = [activeStatus, suspendedStatus, pendingStatus, pendingLoginStatus, suspendedPaymentPending, pendingReturnStatus, pendingInitialQuiz]

        axios.get(axios.defaults.baseUrl + 'user?search={"email": ["' + data + '"],"pt_id":[' + axios.defaults.ptId + '],"status":' + JSON.stringify(status) + '}&photo=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getUserWithChatId(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const activeStatus = 0
        const suspendedStatus = 1
        const pendingStatus = 3
        const pendingLoginStatus = 4
        const suspendedPaymentPending = 5
        const pendingReturnStatus = 6
        const pendingInitialQuiz = 99
        const status = [activeStatus, suspendedStatus, pendingStatus, pendingLoginStatus, suspendedPaymentPending, pendingReturnStatus, pendingInitialQuiz]

        axios.get(axios.defaults.baseUrl + 'user?search={"chat_id": ["' + data + '"],"pt_id":[' + axios.defaults.ptId + '],"status":' + JSON.stringify(status) + '}&photo=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getUserWithDbId(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user?filter={"db_id":' + data.id + '}' + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&photo=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getSettings(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const cache = Utils.getRequestCache('settings-' + data.id, data)
        if (cache) {
            return callback(cache)
        }

        axios.get(axios.defaults.baseUrl + 'settings?db_id=' + data.id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback, data.cacheKey)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateSettings(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'settings?db_id=' + data.id + '&website=1&lng=' + window.language,
            data.settings, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    logout(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'logout?website=1&lng=' + window.language, {}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getTrainingPlans(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        let filter = ''
        if (data && data.client_id) {
            filter = 'db_id=' + data.client_id + (data && (data.type || data.type === 0) ? '&filter={"type":' + data.type + '}' : '') + '&order={"status":0,"id":1}'
        } else {
            filter = 'db_id=' + axios.defaults.ptId + '&filter={"base":1' + (data && (data.type || data.type === 0) ? ',"type":' + data.type + '' : '') + '}'
        }
        if (data && data.ids) {
            filter += '&search={"id":' + JSON.stringify(data.ids) + '}'
        }
        if (data && data.fields) {
            filter += '&fields=' + JSON.stringify(data.fields)
        }
        if (data && data.fast) {
            filter += '&fast=1'
        }

        axios.get(axios.defaults.baseUrl + 'training_plan?' + filter + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getTrainingPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        let filter = ''
        if (data && data.id) {
            filter = '&filter={"id":' + data.id + '}'
        } else {
            filter = '&filter={"status":1}&order={"id":1}&limit=1'
        }

        axios.get(axios.defaults.baseUrl + 'training_plan?db_id=' + userId + filter + (data.fields ? ('&fields=' + JSON.stringify(data.fields) + '&fast=1') : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newTrainingPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.post(axios.defaults.baseUrl + 'training_plan?db_id=' + userId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    editTrainingPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id
        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.patch(axios.defaults.baseUrl + 'training_plan?db_id=' + userId + '&id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteTrainingPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = data.id
        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.delete(axios.defaults.baseUrl + 'training_plan?db_id=' + userId + '&id=' + id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getTrains(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const trngPlanIdBase = -999
        const dates = data && data.start_date && data.end_date ? (',{"f":"date","d":1,"q":5,"v":"' + data.start_date + '","n":1},{"f":"date","d":1,"q":4,"v":"' + data.end_date + '","n":0}') : ''
        const ids = data && data.ids ? (',{"f":"id","q":6,"v":' + JSON.stringify(data.ids) + ',"n":' + (dates ? '1' : '0') + '}') : ''

        axios.get(axios.defaults.baseUrl + 'trng_plan_day?db_id=' + (data && data.client_id ? data.client_id : axios.defaults.ptId) + '&query=[{"f":"trng_plan_id","v":' + trngPlanIdBase + ',"q":0,"n":' + (ids || dates ? '1' : '0') + '}' + ids + dates + ']' + (data && data.fast ? '&fast=1' : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getTrain(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId

        axios.get(axios.defaults.baseUrl + 'trng_plan_day?db_id=' + userId + '&filter={"id":' + data.id + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newTrain(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.post(axios.defaults.baseUrl + 'trng_plan_day?db_id=' + userId + '&website=1&lng=' + window.language,
            data.items ? data.items : data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    editTrain(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id
        const id = data.id
        delete data.id

        axios.patch(axios.defaults.baseUrl + 'trng_plan_day?db_id=' + userId + '&id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteTrain(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId

        axios.delete(axios.defaults.baseUrl + 'trng_plan_day?db_id=' + userId + (data.id ? '&id=' + data.id : '') + (data.ids ? '&ids=' + JSON.stringify(data.ids) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getWorkoutPhotos(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'workout_photos?db_id=' + axios.defaults.ptId + '&search={"id":[1],"workout_id":' + JSON.stringify(data) + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getWorkouts(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const cache = Utils.getRequestCache('workouts', data)
        if (cache) {
            return callback(cache)
        }

        axios.get(axios.defaults.baseUrl + 'workout?db_id=' + axios.defaults.ptId + '&photo=' + (data.photos ? data.photos : 0) + (data.ids ? '&search={"id":' + JSON.stringify(data.ids) + '}' : '') + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback, data.cacheKey)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getWorkout(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'workout?db_id=' + axios.defaults.ptId + '&filter={"id":' + data.id + '}&photo=' + data.photos + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    addWorkout(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'workout?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateWorkout(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id

        axios.patch(axios.defaults.baseUrl + 'workout?db_id=' + axios.defaults.ptId + '&id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteWorkout(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'workout?db_id=' + axios.defaults.ptId + '&id=' + data + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newExercisePhotos(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'workout_photos?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateWorkoutPhotos(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'workout_photos?db_id=' + axios.defaults.ptId + '&workout_id=' + data.id + '&website=1&lng=' + window.language,
            data.photos, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteExercisePhotos(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'workout_photos?db_id=' + axios.defaults.ptId + '&workout_id=' + data.workout_id + '&ids=' + JSON.stringify(data.ids) + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientsPhotos(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user_photo?search={"id":' + JSON.stringify(data) + ',"pt_id":[' + axios.defaults.ptId + ']}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClients(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        if (data.useCache && window.storageClients && window.storageClients['active_clients'] && window.storageClients['active_clients'].length) {
            return callback({
                success: true,
                data: window.storageClients['active_clients']
            })
        }

        const compress = !data.page ? true : false
        const activeStatus = 0
        const pendingLoginStatus = 4
        const pendingReturnStatus = 6
        const status = [data.status]
        if (status.indexOf(activeStatus) > -1) {
            status.push(pendingLoginStatus)
            status.push(pendingReturnStatus)
        }
        let order = '&order={"id":0}'
        if (data.order) {
            order = ''
            data.order.forEach(function (o) {
                if (order) {
                    order += ','
                } else {
                    order = '&order={'
                }
                order += '"' + o.field + '":'
                if (o.mode === 'asc') {
                    order += '0'
                } else {
                    order += '1'
                }
            })
            order += '}'
        }

        axios.get(axios.defaults.baseUrl + 'user?search={"pt_id":[' + axios.defaults.ptId + '],"type":[1],"status":' + JSON.stringify(status) + (data.status2 ? ',"status2":["' + data.status2 + '"]' : '') + (data.payment_pack ? ',"pack_id":[' + data.payment_pack + ']' : '') + '}' + order + '&photo=0&website=1' + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + (window.employeeIdLogged && data.filterEmployee ? '&employee=' + window.employeeIdLogged : '') + (compress ? '&compress=1' : '') + (data.page ? ('&page=' + data.page) : '') + (data.limit ? ('&limit=' + data.limit) : '') + (data.search ? '&input_search={"search":"' + data.search.input + '","fields":' + JSON.stringify(data.search.fields) + '}' : '') + (data.filterId ? ('&filter_id=' + data.filterId) : '') + (data.registers ? '&registers=["questions"]' : '') + '&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
            responseType: compress ? 'arraybuffer' : null,
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientsByChatsIds(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user?search={"pt_id":[' + axios.defaults.ptId + '],"chat_id":' + JSON.stringify(data.chatsIds) + '}' + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&compress=1&photo=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
            responseType: 'arraybuffer',
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientsWithQuery(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const compress = !data.page && !data.compressDisabled ? true : false
        let order = '&order={"id":0}'
        if (data.order) {
            order = ''
            data.order.forEach(function (o) {
                if (order) {
                    order += ','
                } else {
                    order = '&order={'
                }
                order += '"' + o.field + '":'
                if (o.mode === 'asc') {
                    order += '0'
                } else {
                    order += '1'
                }
            })
            order += '}'
        }

        axios.get(axios.defaults.baseUrl + 'user' + (data.query && data.query.indexOf('?') === -1 ? '?' : '') + data.query + (!data.orderDisabled ? order : '') + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + (window.employeeIdLogged && data.filterEmployee ? '&employee=' + window.employeeIdLogged : '') + (compress ? '&compress=1' : '') + (data.page ? ('&page=' + data.page) : '') + (data.limit ? ('&limit=' + data.limit) : '') + (data.search ? '&input_search={"search":"' + data.search.input + '","fields":' + JSON.stringify(data.search.fields) + '}' : '') + (data.filterId ? ('&filter_id=' + data.filterId) : '') + (data.registers ? '&registers=["questions"]' : '') + '&photo=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
            responseType: compress ? 'arraybuffer' : null,
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    exportClientsPayments(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'payments/export?pt_id=' + axios.defaults.ptId + '&start_date=' + data.startDate + '&end_date=' + data.endDate + '&tz=' + window.diffUtcHours + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClient(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user?filter={"pt_id":' + axios.defaults.ptId + ', "id":' + data + '}&photo=1&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPhysicalEvaluations(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data.id ? data.id : axios.defaults.ptId
        const order = data.orderAsc ? 0 : 1
        const search = data.types ? '&search={"type":' + JSON.stringify(data.types) + '}' : ''
        let query = ''
        if (data.startDate && data.endDate) {
            query = '&query=[' + (data.type ? '{"f":"type","v":' + data.type + ',"q":0,"n":1},' : '') + '{"f":"date","v":"' + data.startDate + '","q":5,"n":1,"d":1},{"f":"date","v":"' + data.endDate + '","q":4,"n":0,"d":1}]'
        } else if (data.type || data.type === 0) {
            query = '&query=[{"f":"type","v":' + data.type + ',"q":0}]'
        }

        axios.get(axios.defaults.baseUrl + 'physical_evaluation?db_id=' + userId + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + search + query + '&order={"id":' + order + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPhysicalEvaluationToUpdateWithFeedbackUpdated(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data.id ? data.id : axios.defaults.ptId
        const order = data.orderAsc ? 0 : 1

        axios.get(axios.defaults.baseUrl + 'physical_evaluation?db_id=' + userId + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&filter={"status":0}&order={"id":' + order + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getFirstPhysicalEvaluation(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data.id ? data.id : axios.defaults.ptId
        const cache = Utils.getRequestCache('first_physical_evaluation-' + userId, data)
        if (cache) {
            return callback(cache)
        }

        axios.get(axios.defaults.baseUrl + 'physical_evaluation?db_id=' + userId + '&fields=["id"]&order={"id":0}&limit=1&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback, data.cacheKey)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getLastPhysicalEvaluation(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data.id ? data.id : axios.defaults.ptId
        const cache = Utils.getRequestCache('last_physical_evaluation-' + userId, data)
        if (cache) {
            return callback(cache)
        }

        axios.get(axios.defaults.baseUrl + 'physical_evaluation?db_id=' + userId + '&fields=["id","changes"]&order={"id":1}&limit=1&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback, data.cacheKey)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPhysicalEvaluationsQuestions(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'parq_questions?website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPhysicalEvaluationEvolution(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'phy_eval_evolution?db_id=' + axios.defaults.userDbId + '&start_date=' + data.start + '&end_date=' + data.end + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPhysicalEvaluationPhotos(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'physical_eval_photo?db_id=' + data.client_id + '&filter={"pe_id":' + data.id + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updatePhysicalEvaluationPhotos(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'physical_eval_photo?db_id=' + data.client_id + '&pe_id=' + data.pe_id + '&website=1&lng=' + window.language,
            data.assets, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deletePhysicalEvaluationPhotos(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'physical_eval_photo?db_id=' + data.client_id + '&pe_id=' + data.pe_id + '&ids=' + JSON.stringify(data.ids) + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newPhysicalEvaluation(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.post(axios.defaults.baseUrl + 'physical_evaluation?db_id=' + userId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    editPhysicalEvaluation(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id
        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id
        const validate = data.validate
        delete data.validate
        const ignoreMultipleValidations = data.ignore_multiple_validations
        delete data.ignore_multiple_validations

        axios.patch(axios.defaults.baseUrl + 'physical_evaluation?db_id=' + userId + '&id=' + id + (validate ? '&valid=1' : '') + (ignoreMultipleValidations ? '&ignore_multiple_validations=1' : '') + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deletePhysicalEvaluation(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = data.id
        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.delete(axios.defaults.baseUrl + 'physical_evaluation?db_id=' + userId + '&id=' + id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getNutritionTips(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data ? data : axios.defaults.ptId

        axios.get(axios.defaults.baseUrl + 'nutrition?db_id=' + userId + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newNutritionTip(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id
        data.type = 0

        axios.post(axios.defaults.baseUrl + 'nutrition?db_id=' + userId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    editNutritionTip(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id
        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.patch(axios.defaults.baseUrl + 'nutrition?db_id=' + userId + '&id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteNutritionTip(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = data.id
        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.delete(axios.defaults.baseUrl + 'nutrition?db_id=' + userId + '&id=' + id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getNutritionPlans(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        let filter = ''
        if (data && data.id) {
            filter = 'db_id=' + data.id + '&order={"id":1}'
        } else {
            filter = 'db_id=' + axios.defaults.ptId
        }
        if (data && data.filtered) {
            filter += '&filter={"type":1}'
        }
        if (data && data.fields) {
            filter += '&fields=' + JSON.stringify(data.fields)
        }

        axios.get(axios.defaults.baseUrl + 'nutrition?' + filter + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getNutritionPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId

        axios.get(axios.defaults.baseUrl + 'nutrition?db_id=' + userId + '&filter={"id":' + data.id + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newNutritionPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.post(axios.defaults.baseUrl + 'nutrition?db_id=' + userId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    editNutritionPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id
        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.patch(axios.defaults.baseUrl + 'nutrition?db_id=' + userId + '&id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteNutritionPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = data.id
        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.delete(axios.defaults.baseUrl + 'nutrition?db_id=' + userId + '&id=' + id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getNotifications(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'notification?db_id=' + data + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getAppNotifications(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'notifications?db_id=' + data + '&filter={"type":1}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPhysicalEvaluation(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId

        axios.get(axios.defaults.baseUrl + 'physical_evaluation?db_id=' + userId + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&filter={"id":' + data.id + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getEnums(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'enums?pt_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            let enums = {}
            if (response.data && response.data.data) {
                enums = response.data.data
            }

            Utils.refreshEnums(enums)
            callback()
        }).catch((response) => {
            Utils.refreshEnums({})
            callback()
        })
    },
    sendRegisterPayment(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        data.pt_id = axios.defaults.ptId
        axios.post(axios.defaults.baseUrl + 'register_payment?website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getRegisterPayment(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'register_payment?filter={"id":' + data + ',"pt_id":' + axios.defaults.ptId + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteRegisterPayment(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'register_payment?pt_id=' + axios.defaults.ptId + '&id=' + data + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPayments(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'payment?db_id=' + data + '&order={"date":1}&proof=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPayment(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'payment?db_id=' + data.db_id + (data.id ? '&filter={"id":' + data.id + '}' : '&limit=1&order={"date":1}') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updatePayment(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'payment?db_id=' + data.db_id + '&id=' + data.id + '&website=1&lng=' + window.language,
            data.data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newPayment(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data.db_id
        delete data.db_id

        axios.post(axios.defaults.baseUrl + 'payment?db_id=' + userId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newEmployee(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        data.pt_id = parseFloat(axios.defaults.ptId)

        axios.post(axios.defaults.baseUrl + 'user?website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getEmployeesPhotos(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user_photo?search={"id":' + JSON.stringify(data) + ',"pt_id":[' + axios.defaults.ptId + ']}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getEmployees(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user?search={"type":[2],"pt_id":[' + axios.defaults.ptId + ']' + (data.ids ? ',"id":' + JSON.stringify(data.ids) : '') + ',"status":[0,1]' + (data.sub_type ? (',"sub_type":[' + data.sub_type + ']') : '') + '}&order={"id":0}&photo=0' + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getEmployee(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user?filter={"pt_id":' + axios.defaults.ptId + ', "id":' + data.id + '}&photo=' + (data.photo ? '1' : '0') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getEmployeeClients(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        let query = 'query=[{"f":"pt_id","q":0,"v":' + axios.defaults.ptId + ',"n":1},{"f":"type","q":0,"v":1,"n":1},{"f":"status","q":6,"v":[0,3,4,6],"n":3},{"f":"employee1","q":0,"v":' + data.id + ',"n":2},{"f":"employee2","q":0,"v":' + data.id + ',"n":5}]'
        if (data.start_date && data.end_date) {
            query = 'query=[{"f":"pt_id","q":0,"v":' + axios.defaults.ptId + ',"n":1},{"f":"type","q":0,"v":1,"n":1},{"f":"status","q":6,"v":[0,3,4,6],"n":3},{"f":"employee1","q":0,"v":' + data.id + ',"n":2},{"f":"employee2","q":0,"v":' + data.id + ',"n":6},{"f":"register_date","q":5,"tz":' + window.diffUtcHours + ',"d":1,"v":"' + data.start_date + '","n":1},{"f":"register_date","q":4,"tz":' + window.diffUtcHours + ',"d":1,"v":"' + data.end_date + '","n":0}]'
        }

        axios.get(axios.defaults.baseUrl + 'user?' + query + '&photo=0&fields=["id","name","email","mobile_phone","employee1","employee2","register_date"]&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPermissions(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'permission?db_id=' + data + '&filter={"type": 0}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updatePermissions(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'permission?db_id=' + data.id + '&website=1&lng=' + window.language,
            data.permissions, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getEmployeeSchedule(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'work_schedule?db_id=' + data + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateEmployeeSchedule(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'work_schedule?db_id=' + data.id + '&array=1&website=1&lng=' + window.language,
            data.schedule, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newCode(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'promo_code?db_id=' + axios.defaults.ptId + (window.useCheckout ? '&checkout=1' : '') + (window.useHotmart ? '&hotmart=1' : '') + (window.gatewayType ? ('&gateway_type=' + window.gatewayType) : '') + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateCode(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'promo_code?db_id=' + axios.defaults.ptId + '&code=' + data.code + (window.useCheckout ? '&checkout=1' : '') + (window.useHotmart ? '&hotmart=1' : '') + (window.gatewayType ? ('&gateway_type=' + window.gatewayType) : '') + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getCodes(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        let filter = ''
        if (data.includeExpired) {
            filter = 'filter={"partnership":0,"status":0}'
        } else {
            const today = moment().format('YYYY-MM-DD')
            filter = 'query=[{"f":"partnership","q":0,"v":0,"n":1},{"f":"status","q":0,"v":0,"n":1},{"f":"val_date","q":3,"tz":' + window.diffUtcHours + ',"d":1,"v":"' + today + '","n":0}]'
        }

        axios.get(axios.defaults.baseUrl + 'promo_code?db_id=' + axios.defaults.ptId + '&' + filter + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + (window.useCheckout ? '&checkout=1' : '') + (window.useHotmart ? '&hotmart=1' : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newPartnership(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'partnership?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updatePartnership(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'promo_code?db_id=' + axios.defaults.ptId + '&code=' + data.code + (window.useCheckout ? '&checkout=1' : '') + (window.useHotmart ? '&hotmart=1' : '') + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPartnerships(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'promo_code?db_id=' + axios.defaults.ptId + '&filter={"partnership":1}' + (window.useCheckout ? '&checkout=1' : '') + (window.useHotmart ? '&hotmart=1' : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPartnershipPayments(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'partnership_payment?db_id=' + axios.defaults.ptId + '&code=' + data.code + '&start_date=' + data.startDate + '&end_date=' + data.endDate + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPartnershipClients(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user?filter={"pt_id":' + axios.defaults.ptId + ',"promo_code":"' + data + '"}&order={"id":0}&photo=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPtPayments(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'pt_payment?filter={"id":' + axios.defaults.ptId + '}' + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newContent(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'content?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateContent(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'content?db_id=' + axios.defaults.ptId + '&id=' + data.id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteContent(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'content?db_id=' + axios.defaults.ptId + '&id=' + data + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getContents(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const cache = Utils.getRequestCache('contents', data)
        if (cache) {
            return callback(cache)
        }

        let filter = ''
        if (data && data.type) {
            filter += '&search={"type":[' + data.type + ']}'
        }
        if (data && data.minType) {
            filter += '&query=[{"f":"type","q":2,"v":' + data.minType + '}]'
        }
        if (data && data.id) {
            filter += '&filter={"id":' + data.id + '}'
        }

        axios.get(axios.defaults.baseUrl + 'content?db_id=' + axios.defaults.ptId + filter + '&image=' + (data.photo ? '1' : '0') + (data.order ? '&order={"id":1}' : '') + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback, data.cacheKey)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getWorkLog(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        let filter = ''

        if (data.startDate) {
            filter += '&start_date=' + data.startDate
        }
        if (data.endDate) {
            filter += '&end_date=' + data.endDate
        }

        axios.get(axios.defaults.baseUrl + 'work_log?db_id=' + data.id + filter + '&website=1&lng=' + window.language + '&tz=' + window.diffUtcHours, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getWorkLogSearch(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        if (!data.endDate) {
            data.endDate = data.startDate
        }

        axios.get(axios.defaults.baseUrl + 'work_log/search?pt_id=' + axios.defaults.ptId + (data.clientId ? ('&client_id=' + data.clientId) : '') + '&start_date=' + data.startDate + '&end_date=' + data.endDate + '&website=1&lng=' + window.language + '&tz=' + window.diffUtcHours, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newWorkLog(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'work_log?db_id=' + data.id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getEmployeeAssignClients(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'work_log?db_id=' + data + '&filter={"action":100}&order={"id":1}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    decryptPassword(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'pwd_dec?website=1&lng=' + window.language, {
            password: data,
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getRecipes(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const cache = Utils.getRequestCache('recipes', data)
        if (cache) {
            return callback(cache)
        }

        axios.get(axios.defaults.baseUrlV2 + 'recipe?db_id=' + axios.defaults.ptId + '&image=' + (data.photo ? '1' : '0') + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + (data.fast ? '&fast=1' : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback, data.cacheKey)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    addRecipe(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'recipe?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateRecipe(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id

        axios.patch(axios.defaults.baseUrl + 'recipe?db_id=' + axios.defaults.ptId + '&id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteRecipe(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'recipe?db_id=' + axios.defaults.ptId + '&id=' + data + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getFoods(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const cache = Utils.getRequestCache('foods', data)
        if (cache) {
            return callback(cache)
        }

        axios.get(axios.defaults.baseUrl + 'food?db_id=' + axios.defaults.ptId + '&image=' + (data.photo ? '1' : '0') + (data.ids ? '&search={"id":' + JSON.stringify(data.ids) + '}' : '') + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback, data.cacheKey)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    addFood(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'food?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateFood(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id

        axios.patch(axios.defaults.baseUrl + 'food?db_id=' + axios.defaults.ptId + '&id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteFood(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'food?db_id=' + axios.defaults.ptId + '&id=' + data + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getFoodPlans(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        let filter = ''
        if (data && data.client_id) {
            filter = 'db_id=' + data.client_id + '&filter={"type":2' + (data.status ? (',"status":' + data.status) : '') + '}&order={"status":0,"id":1}'
        } else {
            filter = 'db_id=' + axios.defaults.ptId + '&filter={"base":1,"type":2' + (data.status ? (',"status":' + data.status) : '') + '}'
        }
        if (data && data.fields) {
            filter += '&fields=' + JSON.stringify(data.fields)
        }
        if (data && data.fast) {
            filter += '&fast=1'
        }

        axios.get(axios.defaults.baseUrl + 'food_plan?' + filter + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getFoodPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        let filter = ''
        if (data && data.id) {
            filter = '&filter={"id":' + data.id + '}'
        } else {
            filter = '&filter={"status":1}&order={"id":1}&limit=1'
        }

        axios.get(axios.defaults.baseUrl + 'food_plan?db_id=' + userId + filter + (data.fields ? ('&fields=' + JSON.stringify(data.fields) + '&fast=1') : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newFoodPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.post(axios.defaults.baseUrl + 'food_plan?db_id=' + userId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newAutoFoodPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.post(axios.defaults.baseUrl + 'food_plan/auto?db_id=' + userId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    editFoodPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id
        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.patch(axios.defaults.baseUrl + 'food_plan?db_id=' + userId + '&id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteFoodPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = data.id
        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.delete(axios.defaults.baseUrl + 'food_plan?db_id=' + userId + '&id=' + id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    calculateFoodPlan(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.post(axios.defaults.baseUrl + 'food_plan_calculate?db_id=' + userId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    autoMealAdjustment(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'food_plan/auto/meal?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    searchFoodPlanRecipe(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'food_plan/recipe/search?db_id=' + axios.defaults.ptId + ('&search=' + data.recipe) + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': axios.defaults.authorization
            }
        }).then((response) => {
            Utils.processResponse(response, callback);
        }).catch((response) => {
            Utils.processResponse(response, callback);
        });
    },
    getSubscriptionPlans(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'stripe_products?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': axios.defaults.authorization
            }
        }).then((response) => {
            Utils.processResponse(response, callback);
        }).catch((response) => {
            Utils.processResponse(response, callback);
        });
    },
    sendFeedbackReply(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.post(axios.defaults.baseUrl + 'phy_eval_reply?db_id=' + userId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getTrainingPlanProgressExercises(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'trng_plan_prog_exs?db_id=' + data + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getTrainingPlanProgressData(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'trng_plan_prog?db_id=' + data.clientId + (data.workoutId ? ('&filter={"workout_id":' + data.workoutId + '}') : '') + (data.workoutIds ? ('&search={"workout_id":' + JSON.stringify(data.workoutIds) + '}') : '') + (data.startDate ? '&start_date=' + data.startDate : '') + (data.endDate ? '&end_date=' + data.endDate : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    sendAppNotification(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }
        axios.post(axios.defaults.baseUrl + 'notification/push?website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    sendAppManualNotification(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const userId = data && data.client_id ? data.client_id : axios.defaults.ptId
        delete data.client_id

        axios.post(axios.defaults.baseUrl + 'notifications?db_id=' + userId + 'website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getImage(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'file?db_id=' + axios.defaults.ptId + '&filter={"id":' + data + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newImage(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'file?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateImage(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id

        axios.patch(axios.defaults.baseUrl + 'file?db_id=' + axios.defaults.ptId + '&id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteImage(id, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'file?db_id=' + axios.defaults.ptId + '&id=' + id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getStatisticServiceUse(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'statistic/service_use?db_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getStatisticFeedbacksAvg(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'statistic/feedbacks-avg?pt_id=' + axios.defaults.ptId + '&start_date=' + data.start_date + '&end_date=' + data.end_date + '&tz=' + window.diffUtcHours + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getStatisticRegistersAvg(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'statistic/registers-avg?pt_id=' + axios.defaults.ptId + '&start_date=' + data.start_date + '&end_date=' + data.end_date + '&tz=' + window.diffUtcHours + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getStatisticValidationsAvg(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'statistic/validations-avg?pt_id=' + axios.defaults.ptId + '&start_date=' + data.start_date + '&end_date=' + data.end_date + '&tz=' + window.diffUtcHours + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getStatisticEmployeesFeedbacksAvg(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'statistic/employees/feedbacks-avg?pt_id=' + axios.defaults.ptId + '&start_date=' + data.start_date + '&end_date=' + data.end_date + '&tz=' + window.diffUtcHours + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    refreshHotmartConnections(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'hotmart/connect?pt_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    syncHotmartSubscription(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'hotmart/subscription/sync?pt_id=' + axios.defaults.ptId + '&id=' + data + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getCountries(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'countries?website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            const items = []
            response.data.forEach(function (item) {
                const key = item.translations.por.common
                items.push({
                    label: key,
                    value: key
                })
            })
            callback({
                success: true,
                data: items,
                message: null
            })
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientsCountries(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'user/countries?website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getCalendarItems(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        let query = ''
        let datesQuery = data.start_date && data.end_date ? ',{"f":"start_date","d":1,"q":5,"v":"' + data.start_date + '","n":1},{"f":"end_date","d":1,"q":4,"v":"' + data.end_date + '","n":0}' : ''
        let statusQuery = data.status || data.status === 0 ? ',{"f":"status","q":0,"v":' + data.status + ',"n":' + (datesQuery ? '1' : '0') + '}' : ''
        if (data.user_id) {
            query = 'query=[{"f":"pt_id","q":0,"v":' + axios.defaults.ptId + ',"n":1},{"f":"user_id","q":0,"v":' + data.user_id + ',"n":1}' + statusQuery + datesQuery + ']'
        } else {
            query = 'query=[{"f":"pt_id","q":0,"v":' + axios.defaults.ptId + ',"n":1}' + statusQuery + datesQuery + ']'
        }

        axios.get(axios.defaults.baseUrl + 'calendar/item?' + query + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newCalendarItem(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        data.pt_id = axios.defaults.ptId

        axios.post(axios.defaults.baseUrl + 'calendar/item?website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateCalendarItem(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id
        const userId = parseFloat(data.user_id)
        delete data.user_id

        axios.patch(axios.defaults.baseUrl + 'calendar/item?pt_id=' + axios.defaults.ptId + (userId ? '&user_id=' + userId : '') + '&item_id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteCalendarItem(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id
        const userId = parseFloat(data.user_id)
        delete data.user_id

        axios.delete(axios.defaults.baseUrl + 'calendar/item?pt_id=' + axios.defaults.ptId + (userId ? '&user_id=' + userId : '') + '&item_id=' + id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClintSourcesDeal(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'deal/sources?pt_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    importClintSourcesDeal(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'deal/import?pt_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClintGeneralStatisticsDeal(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'deal/statistics/general?pt_id=' + axios.defaults.ptId + '&start_date=' + data.startDate + '&end_date=' + data.endDate + (data.source ? ('&source=' + data.source) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClintSourcesStatisticsDeal(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'deal/statistics/sources?pt_id=' + axios.defaults.ptId + '&start_date=' + data.startDate + '&end_date=' + data.endDate + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    checkClintDeal(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'deal/check?pt_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            {}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClintDeal(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'deal?pt_id=' + axios.defaults.ptId + (data.source ? ('&source=' + data.source) : '') + (data.startDate ? ('&start_date=' + data.startDate) : '') + (data.endDate ? ('&end_date=' + data.endDate) : '') + (data.daysMin || data.daysMin === 0 ? ('&deal_done_days_min=' + data.daysMin) : '') + (data.daysMax ? ('&deal_done_days_max=' + data.daysMax) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClintDealUnconverted(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'deal?query=[{"f":"pt_id","q":0,"v":' + axios.defaults.ptId + ',"n":1},{"f":"closed","q":0,"v":0,"n":1},{"f":"closed_external","q":0,"v":0,"n":1},{"f":"date","d":1,"q":5,"v":"' + data.startDate + '","n":1},{"f":"date","d":1,"q":4,"v":"' + data.endDate + '","n":0}]&fields=["name","email","phone"]&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClintDealRecords(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'deal?query=[{"f":"pt_id","q":0,"v":' + axios.defaults.ptId + ',"n":1},{"f":"date","d":1,"q":5,"v":"' + data.startDate + '","n":1},{"f":"date","d":1,"q":4,"v":"' + data.endDate + '","n":0}]&fields=["name","email","phone","source","date","closed","date_closed","closed_external","closed_source"]&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClintExternalSourcesStatisticsDeal(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'deal/statistics/external-sources?pt_id=' + axios.defaults.ptId + '&source=' + data.source + '&start_date=' + data.startDate + '&end_date=' + data.endDate + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getReportIssues(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'issue_report?filter={"pt_id":' + axios.defaults.ptId + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newReportIssue(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        data.pt_id = axios.defaults.ptId
        axios.post(axios.defaults.baseUrl + 'issue_report?website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateReportIssue(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        data.pt_id = axios.defaults.ptId
        axios.patch(axios.defaults.baseUrl + 'issue_report?id=' + data.id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteReportIssue(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'issue_report?id=' + data.id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getTasks(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'task?filter={"pt_id":' + axios.defaults.ptId + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newTask(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        data.pt_id = axios.defaults.ptId
        axios.post(axios.defaults.baseUrl + 'task?website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateTask(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        data.pt_id = axios.defaults.ptId
        axios.patch(axios.defaults.baseUrl + 'task?id=' + data.id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteTask(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'task?id=' + data.id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPaymentsPacks(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'pack?db_id=' + axios.defaults.ptId + '&query=[' + (data && (data.status || data.status === 0) ? ('{"f":"status","q":0,"v":' + data.status + ',"n":0}') : '{"f":"status","q":1,"v":99,"n":0}') + ']' + (data && data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newPaymentPack(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'pack?db_id=' + axios.defaults.ptId + 'website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updatePaymentPack(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'pack?db_id=' + axios.defaults.ptId + '&id=' + data.id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deletePaymentPack(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'pack?db_id=' + axios.defaults.ptId + '&id=' + data.id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getSubscriptionVW(data, callback) {
        axios.get(axios.defaults.baseUrl + 'vw/subscription/info?pt_id=' + axios.defaults.ptId + '&id=' + data.id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            }
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    generateRegisterPaymentInvoice(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'register_payment/invoice?pt_id=' + axios.defaults.ptId + '&id=' + data.id + '&website=1&lng=' + window.language,
            {}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    generateClientPaymentInvoice(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.patch(axios.defaults.baseUrl + 'payment/invoice?db_id=' + data.db_id + '&id=' + data.id + '&website=1&lng=' + window.language,
            {}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    sendEmail(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(axios.defaults.baseUrl + 'email/send?pt_id=' + axios.defaults.ptId + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getTalkjsToken(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'talkjs/user/token?user_id=' + data.id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateTalkjsConversation(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = data.id
        delete data.id

        axios.patch(axios.defaults.baseUrl + 'talkjs/conversation?conversation_id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    postRequest(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.post(data.url,
            data.json, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((response) => {
            callback({
                success: response.status === 200 ? true : false,
                data: response.data,
                message: response.statusText
            })
        }).catch((response) => {
            callback({
                success: response.status === 200 ? true : false,
                data: response.data,
                message: response.statusText
            })
        })
    },
    getLives(callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.get(axios.defaults.baseUrl + 'lives?filter={"pt_id":' + axios.defaults.ptId + ',"status":"active"}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newLive(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        data.pt_id = axios.defaults.ptId

        axios.post(axios.defaults.baseUrl + 'lives?website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateLive(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        const id = parseFloat(data.id)
        delete data.id

        axios.patch(axios.defaults.baseUrl + 'lives?pt_id=' + axios.defaults.ptId + '&id=' + id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteLive(data, callback) {
        if (!this.hasInternet(callback)) {
            return false
        }

        axios.delete(axios.defaults.baseUrl + 'lives?pt_id=' + axios.defaults.ptId + '&id=' + data.id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
}
